/* eslint-disable */
import React, { useState } from 'react';
import { createGlobalState } from "react-hooks-global-state";
import Cookies from "universal-cookie";


const oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

export const userList  = [
    {clientId : 1, clientName : 'eq_hedge',accountIds:[1] },
    {clientId : 1, clientName : 'div', accountIds: [2] },
    {clientId : 3, clientName : 'sankar', accountIds: [1] },
    {clientId : 4, clientName : 'zen_test', accountIds: [1] },
]
export const cookies = new Cookies(); 
//for(const i in userList)
    //cookies.set(userList[i].clientId, userList[i].clientName, { sameSite: 'strict', expires: oneYearFromNow });
    
//cookies.set('clientId', 3, { sameSite: 'strict', expires: oneYearFromNow });
//cookies.set('clientName', 'sankar', { sameSite: 'strict', expires: oneYearFromNow });


const { setGlobalState, useGlobalState, subscribe } = createGlobalState({
    clientId:cookies.get('clientId') , clientName : cookies.get('clientName'), accountIds : cookies.get('accountIds'), brokerName : cookies.get("brokerName")
},);

export { useGlobalState, setGlobalState, subscribe };