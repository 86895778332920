/*eslint-disable*/
import React, { useState } from "react";
import { LineChart, Line, Tooltip, XAxis, YAxis, Legend} from 'recharts';
import { ArrowDownCircleIcon, ArrowUpCircle } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setPnlType, setIsBtnClick, setType } from "./PnlLineChartSlice";

const PnlLineChartComponent = () =>{
    const chartData = useSelector(state => state.pnlLineChart.lineChartData);
    const unRealizedPnlData = chartData?.map((item)=>{
        return(
            {
                // unrealizedDTDPnl: item?.unRealizedDTDPnl,
                // unrealizedWTDPnl: item?.unRealizedWTDPnl,
                // unrealizedMTDPnl: item?.unRealizedMTDPnl,
                // unrealizedYTDPnl: item?.unRealizedYTDPnl,
                // unrealizedLTDPnl: item?.unRealizedLTDPnl,
                // realizedDTDPnl: item?.realizedDTDPnl,
                // realizedWTDPnl: item?.realizedWTDPnl,
                // realizedMTDPnl: item?.realizedMTDPnl,
                // realizedYTDPnl: item?.realizedYTDPnl,
                // realizedLTDPnl: item?.realizedLTDPnl,
                // realizedYTDPnl : item?.totalRealizedYTDPnl,
                // unrealizedYTDPnl : item?.totalUnRealizedYTDPnl,
                totalPnl : item?.totalPnl,
                date: item?.date
            }
        )
    });

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    
    const dispatch = useDispatch();
    const targetSymbol = useSelector(state => state.pnlLineChart.targetSymbol);
    const pnlType = useSelector(state => state.pnlLineChart.pnlType);
    const isBtnClick = useSelector(state => state.pnlLineChart.isBtnClick);
    const type = useSelector(state => state.pnlLineChart.type)
    console.log(`${pnlType}${type}Pnl`);
    const handlePnlType = ()=>{
        if(pnlType==="realized"){
            dispatch(setPnlType("unrealized"));
        }else{
            dispatch(setPnlType("realized"));
        }
    }
    const CustomTooltip = ({ active, payload, label }) => {
        let month = new Date(payload[0]?.payload?.date).getMonth();
        let dateNumber = new Date(payload[0]?.payload?.date).getDate();
        if (active && payload && payload.length) {
          return (
            <div className="bg-slate-300/80 h-10 w-auto px-2 py-2 rounded-sm">
              <p className="text-sm font-semibold">{`${monthNames[month]?.slice(0,3)} ${dateNumber<10?('0'+dateNumber):dateNumber} :  ${parseFloat(payload[0].value).toFixed(2)}`}</p>
            </div>
          );
        }
        return null;
    };
    const CustomLegend = () => {
        return (
            <ul className="flex space-x-4">
                <li className="flex items-center">
                    <span className="block w-10 h-1" style={{ backgroundColor: '#8884D8' }} />
                    <span className="ml-2">Total YTD Pnl</span> {/* Custom legend text */}
                </li>
            </ul>
        );
    };
    return(
        <div className="flex flex-col space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-12">
        <div className='relative max-w-lg h-full px-2 py-4 border-[1px] rounded-md shadow-md'>
        <h2 className="text-lg font-semibold text-center mb-4">Total YTD Pnl</h2>
            {/* <div className="flex-row -mt-4 mb-2">
                <p className="text-sm font-semibold">{targetSymbol?targetSymbol:"Select a stock to see PnL data"}</p> 
                {
                pnlType==='realized'?
                <span
                class="inline-flex items-center rounded-md
                bg-green-50 px-2 py-1 text-xs font-semibold text-green-700
                ring-1 ring-inset ring-green-600/20 cursor-pointer" onClick={handlePnlType}>
                    {pnlType.toLocaleLowerCase()} P&L
                </span>
                :
                <span
                class="inline-flex items-center rounded-md
                bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-700
                ring-1 ring-inset ring-purple-600/20 cursor-pointer" onClick={handlePnlType}>
                    {pnlType.toLocaleLowerCase()} P&L
                </span>
                }
            </div> */}
            <LineChart width={500} height={300} data={unRealizedPnlData}>
                {/* <CartesianGrid strokeDasharray={"5"} vertical={false}/> */}
                <XAxis 
                    dataKey="date"
                    tickFormatter={(date) => new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                    angle={-45}
                    tick={{ fontSize: 10 }}
                    dy={5}
                />
                <YAxis 
                    tick={{ fontSize: 10 }}
                    tickFormatter={(value) => {
                        const absValue = Math.abs(value);
                        let formattedValue;
                        
                        if (absValue >= 1_000_000) {
                            formattedValue = `${(absValue / 1_000_000)}M`;
                        } else if (absValue >= 1_000) {
                            formattedValue = `${(absValue / 1_000)}K`;
                        } else {
                            formattedValue = absValue;
                        }

                        return formattedValue;
                    }} 
                />
                 {/* <div className="flex justify-center">
                    <Legend 
                        content={<CustomLegend />} 
                        verticalAlign="bottom" 
                        height={36} 
                    />
                </div> */}
                <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }}/>
                <Line type="monotone" dataKey={`totalPnl`} stroke="#8884D8" strokeWidth={1.5} />
            </LineChart>
            <div className="flex justify-center mt-2" >
                    <CustomLegend />
                </div>
            {/* <div className="absolute top-0 right-0 group p-2 h-[40px]">
                <button
                onClick={()=> dispatch(setIsBtnClick(!isBtnClick))}
                className="bg-white border border-slate-200 rounded-md font-semibold text-sm p-2 inline-flex space-x-2 items-center w-24 z-20">
                        <svg class="w-7 h-7 text-indigo-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16M8 14h8m-4-7V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"/>
                        </svg>
                        <span className="mr-1 font-semibold">{(type==='undefined'|| !type)?'Type':type}</span>
                        {
                            !isBtnClick?
                            <ArrowDownCircleIcon size={24}/>
                            :<ArrowUpCircle size={24}/>
                        }
                </button>
                <ul className={
                    `${isBtnClick?"":"hidden " } mt-1 top-full min-w-[80px] bg-white/90 border border-slate-200 p-2 rounded-lg shadow-lg`
                }>
                    {
                        ["DTD","WTD","MTD","YTD","LTD"].map((value,index)=>{
                            return(
                                <li key={index}>
                                    <p className={
                                        `text-slate-800 ${type===value?" bg-slate-200 ":""}
                                        flex items-center p-2 text-sm`
                                    } onClick={()=>{
                                        dispatch(setType(value));
                                        dispatch(setIsBtnClick(false))
                                    }}>
                                        <span className="whitespace-nowrap">{value}</span>
                                    </p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div> */}
               
        </div>
        </div>
    )
}
export default PnlLineChartComponent
