/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editFormOpen : false,
  editableOrderData : {},
  lots : 1,
  lotSize : 1
};

const EditOrderFormSlice = createSlice({
  name: 'editOrderForm',
  initialState,
  reducers: {
    
    setEditFormOpen(state, action){
        state.editFormOpen = action.payload
    },
    setEditableOrderData(state, action){
      state.editableOrderData = action.payload
    },
    setLots(state, action ){
      state.lots = action.payload
    },
    setLotSize(state, action){
      state.lotSize = action.payload
    }
  }
});

export const { setEditFormOpen, setEditableOrderData, setLots, setLotSize} = EditOrderFormSlice.actions;
export default EditOrderFormSlice.reducer;
