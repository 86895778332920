export const validateQuantity = (value) => 
{
    if (!value || value < 1 || value > 10000) {
      return "Enter Quantity (1 - 10000)";
    }
  
    const isValid = /^-?\d+(\.\d+)?$/.test(value);
    if (!isValid) {
      return "Quantity should not include characters";
    }
  
    return true; // Return true if validation passes
};


export const validatePrice = (value) => 
{
    if (value != null && (value < 0 || value > 20000)) {
      return "Enter Quantity (0 - 20000)";
    }
  
    const isValid = /^-?\d+(\.\d+)?$/.test(value);
    if (!isValid) {
      return "Price should not include characters";
    }
  
    return true; // Return true if validation passes
};
  