/*elslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const positionActionSlice = createSlice({
    name: 'positionActions',
    initialState: {
        formOpen:false,
        tradingSymbol:"",
        type:"",
        orderDetails:null,
        isConfirmed:false,
        open:false,
        orderData:null,
        filterText : "",
        positionZenId: ""
    },
    reducers: {
        saveTradingSymbol: {
            reducer: (state, action) => {
                state.tradingSymbol = action.payload.tradingSymbol;
            },
            prepare: (tradingSymbol) => ({ payload: { tradingSymbol } })
        },
        saveType: {
            reducer: (state, action) => {
                state.type = action.payload.type;
            },
            prepare: (type) => ({ payload: { type} })
        },
        saveFormOpen:{
            reducer: (state, action) => {
                state.formOpen = action.payload.formOpen;
            },
            prepare: (formOpen) => ({ payload: { formOpen} })
        },
        saveOrderDetails:{
            reducer: (state, action) => {
                state.orderDetails = action.payload.orderDetails;
            },
            prepare: (orderDetails) => ({ payload: { orderDetails} })
        },
        saveIsConfirmed:{
            reducer: (state, action) => {
                state.isConfirmed = action.payload.isConfirmed;
            },
            prepare: (isConfirmed) => ({ payload: { isConfirmed} })
        },
        saveOpen:{
            reducer: (state, action) => {
                state.open = action.payload.open;
            },
            prepare: (open) => ({ payload: { open} })
        },
        saveOrderData:{
            reducer: (state, action) => {
                state.orderData = action.payload.orderData;
            },
            prepare: (orderData) => ({ payload: { orderData} })
        },
        setFilterText(state, action){
            state.filterText = action.payload;
        },
        savePositionZenId(state,action){
            state.zenId = action.payload;
        }

    },
});

export const { saveTradingSymbol,saveFormOpen,saveType, saveOpen, saveIsConfirmed, saveOrderDetails, 
    saveOrderData, setFilterText,savePositionZenId } = positionActionSlice.actions;

export default positionActionSlice.reducer;
