/**eslint-disable**/
import Form from "react-bootstrap/Form";
import {Autocomplete, TextField } from "@mui/material";
import { MdCancel } from "react-icons/md";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import config from '../../config';
import { setEditFormOpen,setEditableOrderData, setLots } from "./EditOrderFormSlice";
// import  from '../OrderForm/Field/OrderForm.css';
const EditOrderForm = () => {
    const dispatch = useDispatch();
    const lots = useSelector(state => state.editOrderForm.lots);
    const lotSize = useSelector(state => state.editOrderForm.lotSize);
    const data = useSelector(state => state.editOrderForm.editableOrderData);
    useEffect(()=> console.log("Data :", data),[data]);
    const strategyOptions = [
        { label: "One", value: 1 },
        { label: "2PM_STRATEGY", value: 11000 },
        { label: "2PM_STRATEGY_TEST1", value: 11001 },
        { label: "2PM_STRATEGY_TEST2", value: 11002 }
      ];
    console.log("Data :",data, data.orderType, data.quantity, data.limitPrice)
    return (
    <form 
        className="form cursor-auto" 
        style={{backgroundColor: "white", boxShadow: "1px 1px 5px"}}
        onSubmit={(e) => {
            e.preventDefault();
            fetch(config.REACT_APP_DEV_URL+'order/placeOrder',
        {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body:JSON.stringify(
                {
                    clientId : data.clientId,
                    accountId : data.accountId,
                    strategyId : data.strategyId,
                    broker : data.broker,
                    exchange : data.exchange,
                    transactionType : data.transactionType,
                    quantity : data.quantity,
                    product: data.product,
                    validity : data.validity,
                    minutes : data.minutes,
                    stopLossLimitPrice : data.stopLossLimitPrice,
                    triggerPrice : data.triggerPrice,
                    limitPrice : data.limitPrice,
                    tradingSymbol : data.tradingSymbol,
                    orderType : data.orderType,
                    methodType : "PUT",
                    zenOrderId : data.zenOrderId
                }
            ),
        })
        .then(response=>{
            if (!response.ok) {
              throw new Error('Not able to place the order');
            }
            return response.text();
          }
        )
        .then(json=>alert(json))
        .then(()=>{
          dispatch(setEditFormOpen(false));

        })
        .catch((err) => {
            alert("An error occurred: " + err.message);
        });
        }}
    >
        <div style={{backgroundColor: "blue", height: "50px",cursor: "grabbing", display: "flex", paddingLeft:"10px",marginBottom: "20px", alignItems: "center"}}>
            <div style={{color:"white", width:"450px"}}>{data.transactionType} {data.tradingSymbol}</div>
            <button onClick={() => dispatch(setEditFormOpen(false))}><MdCancel className="text-white hover:scale-150" /></button>
        </div>
        <div className="form-div">
          {/* <Box>
            <Tabs
              value={"Regular"}
              aria-label="Tabs where each tab needs to be selected manually"
            >
              <Tab label="Regular" value={"Regular"}/>
              <Tab label="Cover" value={"Cover"}/>
              <Tab label="AMO" value={"AMO"}/>
              <Tab label="Iceberg" value={"Iceberg"}/>
            </Tabs>
          </Box> */}
          <div className="tab" style={{width: "100px"}}>
              
              <div>
              {/* <label className="form-label" style={{color:"black"}}>Order</label> */}
              <Autocomplete
              disablePortal
              disabled = {true}
              id="combo-box-demo"
              options={strategyOptions}
            //   defaultValue={strategyOptions.find(option => option.value === data.strategyId)}
              value = {strategyOptions.find(option => option.value === data.strategyId)}
              onChange={(e, value) => dispatch(setEditableOrderData({...data, strategyId : value.value}))}
              getOptionLabel={option => option.label}
              sx={{
                width: 230,
                "& .MuiInputBase-root": {
                  fontSize: ".8rem",
                  height: "40px",
                  fontWeight: "500",
                  bgcolor: "white"
                },
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined"  label="Strategy"/>
              )}
            />
            </div>
              <div>
              {/* <label className="form-label" style={{color:"black"}}>Order</label> */}
              <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e, value) => {
                if(value === "MARKET")
                {
                    console.log("Present in market"); 
                    dispatch(setEditableOrderData({...data, limitPrice : null, orderType : value}))
                }
                else 
                dispatch(setEditableOrderData({...data, orderType : value}))}}
              options={[
                "MARKET",
                "LIMIT",
                // "STANDALONE_SL_LIMIT",
                // "STANDALONE_SL_MARKET",
                // "LIMIT_ORDER_WITH_SL_LIMIT",
                // "MARKET_ORDER_WITH_SL_LIMIT",
                // "LIMIT_ORDER_WITH_SL_MARKET",
                // "MARKET_ORDER_WITH_SL_MARKET",
              ]}
            //   defaultValue={data.orderType}
              value = {data.orderType}
              sx={{
                width: 230,
                "& .MuiInputBase-root": {
                  fontSize: ".8rem",
                  height: "40px",
                  fontWeight: "500",
                  bgcolor: "white"
                },
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined"  label="Order Type"/>
              )}
            />
            </div>
              
            </div>
          <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue={data.product}
                value={data.product}
                name="radio-buttons-group"
                onChange={(e, value) => dispatch(setEditableOrderData({...data, product : value}))}
            >
                <FormControlLabel value="CNC" control={<Radio />} label="CNC" />
                <FormControlLabel value="MIS" control={<Radio />} label="MIS" />
                <FormControlLabel value="NRML" control={<Radio />} label="NRML" />
            </RadioGroup>
            </FormControl>
            {/* <div style={{display: "flex", gap: "10px"}}>
            <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Trigger Price :</Form.Label>
              <Form.Control                
                placeholder="Trigger Price"                
                value={data.triggerPrice}
                className="input-size"
                
              />
              </Form.Group>

            
            <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Stop Loss Price :</Form.Label>
              <Form.Control
                placeholder="Stop Loss Price"
                className="input-size"   
                value={data.stopLossLimitPrice}            
                
              />
            </Form.Group>

          </div> */}
          <div style={{display: "flex", gap: "10px"}}>
          <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Lots :</Form.Label>
              <Form.Control                
                placeholder="Lots Size"                
                value = {lots}
                type="number"
                className="input-size"
                disabled = {!(data?.tradingSymbol?.includes("CE") || data?.tradingSymbol?.includes("PE") || data?.tradingSymbol?.includes("FUT"))}
                onChange = 
                {
                  (e) => 
                  {
                    dispatch(setLots(e?.target?.value ));
                    dispatch(setEditableOrderData({...data, quantity : e?.target?.value * lotSize}));
                  }
                }
              />
              </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Quantity :</Form.Label>
              <Form.Control
                type="number"
                placeholder="Quantity"
                value={data.quantity}
                required = {true}
                disabled = {data?.tradingSymbol?.includes("CE") || data?.tradingSymbol?.includes("PE") || data?.tradingSymbol?.includes("FUT")}
                className="input-size"
                step={lotSize}
                onChange={(e) => {
                    dispatch(setLots(e?.target?.value / lotSize));
                    dispatch(setEditableOrderData({...data, quantity : e.target.value}));
                }}
                
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Limit Price :</Form.Label>
              <Form.Control
                placeholder="Limit Price"
                className="input-size"
                type="number" 
                required = {data.orderType !== "MARKET" || data.orderType !== "STANDALONE_SL_LIMIT" || data.orderType !== "MARKET_ORDER_WITH_SL_LIMIT" || data.orderType !== "MARKET_ORDER_WITH_SL_MARKET"}
                value={(data.orderType === "MARKET" || data.orderType === "STANDALONE_SL_LIMIT" || data.orderType === "MARKET_ORDER_WITH_SL_LIMIT" || data.orderType === "MARKET_ORDER_WITH_SL_MARKET")? "" : data.limitPrice}              
                disabled={data.orderType === "MARKET" || data.orderType === "STANDALONE_SL_LIMIT" || data.orderType === "MARKET_ORDER_WITH_SL_LIMIT" || data.orderType === "MARKET_ORDER_WITH_SL_MARKET"}
                onChange={(e) =>  data.orderType === "MARKET"? dispatch(setEditableOrderData({...data, limitPrice : null})) : dispatch(setEditableOrderData({...data, limitPrice : e.target.value}))}
              />
            </Form.Group>
          </div>
          
        </div>

        <div>
          <button type="submit" style={{backgroundColor: "blue", color: "white", padding:"10px", marginLeft : "270px", marginBottom: "20px",marginTop: "10px", borderRadius:"5px"}}>Update
          </button> 
          <button 
            style=
              {
                {
                  backgroundColor: "blue", 
                  color: "white", 
                  padding:"10px", 
                  marginLeft : "20px", 
                  marginBottom: "20px", 
                  marginTop: "10px",
                  borderRadius:"5px"
                  }
              }
            onClick=
            {
              () => {
                fetch(config.REACT_APP_DEV_URL+'order/placeOrder',
                {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body:JSON.stringify
                    (
                      {
                          clientId : data.clientId,
                          accountId : data.accountId,
                          strategyId : data.strategyId,
                          broker : data.broker,
                          exchange : data.exchange,
                          transactionType : data.transactionType,
                          quantity : data.quantity,
                          product: data.product,
                          validity : data.validity,
                          minutes : data.minutes,
                          stopLossLimitPrice : data.stopLossLimitPrice,
                          triggerPrice : data.triggerPrice,
                          limitPrice : data.limitPrice,
                          tradingSymbol : data.tradingSymbol,
                          orderType : data.orderType,
                          methodType : "DELETE",
                          zenOrderId : data.zenOrderId
                      }
                    ),
                })
                .then(response=>{
                    if (!response.ok) {
                      throw new Error('Not able to place the order');
                    }
                    return response.text();
                  }
                )
                .then(json=>alert(json))
                .then(()=>{
                  dispatch(setEditFormOpen(false));

                })
                .catch((err) => {
                    alert("An error occurred: " + err.message);
                });
              }
            }
          >Cancel Order
          </button> 
        </div>
       
      </form>)
}

export default EditOrderForm;