/*eslint-disable*/
import { RiMoneyRupeeCircleFill } from "react-icons/ri";

const Margin = ({availableMargin, priceGetter}) => {
    return(
        
        <span className="font-semibold flex items-center space-x-2 pl-2">
            <RiMoneyRupeeCircleFill className="text-md" size={20} />{priceGetter(availableMargin)}
        </span>
    )
}

export default Margin;