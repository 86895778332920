/*eslint-disable*/
import { useGlobalState } from 'components/globalVariable';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PnlLineChartComponent from './PnlLineChartComponent';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { Autocomplete } from '@mui/material';
import {TextField} from '@mui/material';
import config from "../../config";
import {
    Button,
    Input,
    Form,
    Label,
    FormGroup,
    Col
    } from 'reactstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setDateRange, setLineChartData, setTargetSymbol, setIsFetching, setType, setStocks } from './PnlLineChartSlice';
function PnlLineChartContainer() {
    const { dates,broker, security, exchange, portfolio, entity, strategy } = useSelector(state => state.filter);
    const [clientId]= useGlobalState("clientId");
    const [accountIds] = useGlobalState("accountIds");
    const [clientName] = useGlobalState('clientName');
    const prevClientNameRef = React.useRef(clientName);
    const stocks = useSelector(state => state.pnlLineChart.stocks);
    const targetSymbol = useSelector(state => state.pnlLineChart.targetSymbol);
    const isFetching = useSelector(state => state.pnlLineChart.isFetching);
    const type = useSelector(state => state.pnlLineChart.type);
    const dateRange = useSelector(state => state.pnlLineChart.dateRange);
    const dispatch = useDispatch();
    const dateConversion = (val) => {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    
    useEffect(()=>{
        console.log("Present in target Symbol", prevClientNameRef.current , clientName);
        if(prevClientNameRef.current !== clientName)
        {
            dispatch(setTargetSymbol(null));
            dispatch(setLineChartData(null));
            // dispatch(setType(null));
        }
    },[clientName])
    
    useEffect(() => {
        dispatch(setIsFetching(true));
        const body = {
            "startTimestamp": `${dateConversion(dateRange[0]?.toLocaleDateString() )} 23:15:00`,
            "endTimestamp": `${dateConversion(dateRange[1]?.toLocaleDateString() )} 23:15:00`,
            "positionCompKeyFilter":{
                "clientId":clientId,
                ...(strategy !== "" && { strategyIds: [strategy] }), 
                //"brokers":["ZERODHA","ZEN_BROKER"],
                "accountIds":accountIds,
                "tradingSymbols": security === "" ? [] :[security]
                }
            }
        const fetchData = () => {
        fetch(config.REACT_APP_DEV_URL+'pnl',
        {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        .then(result => result.json())
        .then(rowData => {
            
            let arr = Array.from(rowData).map((data)=>data.tradingSymbol)
            console.log(arr)
            //this should come from backend with diffrent service point!
            dispatch(setStocks([...new Set(arr)].sort()));
            setTimeout(()=>dispatch(setIsFetching(false)),750);
        })
        .catch((err) => {
            console.log("render error");
        });
        }
        fetchData();
        const intervalId = setInterval(fetchData, 120000);
        //setIntervel cleaning process
        return(()=>{
            clearInterval(intervalId);
        })
      }, [security, dates,  accountIds]);
    
    useEffect(() => {
        const fetchData = () => {
        // if(targetSymbol==="")return;
        //     dispatch(setIsFetching(true));
        fetch(config.REACT_APP_DEV_URL+'pnl',
        {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({  //  15 (days) * 24 (hours) * 60 (minutes) * 60 (seconds) * 1000 (milliseconds )
                "startTimestamp": `${dateConversion(dateRange[0]?.toLocaleDateString() )} 23:15:00`,
                "endTimestamp":  `${dateConversion(dateRange[1].toLocaleDateString())} 23:15:00` ,
                "positionCompKeyFilter":{
                    "clientId":clientId,
                    ...(strategy !== "" && { strategyIds: [strategy] }), 
                    // "strategyIds":[1],
                    //"brokers":["ZERODHA","ZEN_BROKER"],
                    "accountIds":accountIds,
                    "tradingSymbols": []
                    }
                }),
        })
        .then(result => result.json())
        .then(rowData => {
            console.log("Present")
            // console.log(rowData)
            // dispatch(setLineChartData(Array.from(rowData)));
            const aggregatedData = rowData.reduce((acc, item) => {
                const { date, unRealizedYTDPnl, realizedYTDPnl } = item;
              
                if (!acc[date]) {
                  acc[date] = { date, totalPnl: 0 };
                }
              
                acc[date].totalPnl += (unRealizedYTDPnl + realizedYTDPnl);
              
                return acc;
              }, {});
              
              dispatch(setLineChartData(Object.values(aggregatedData)))
            setTimeout(()=>{ dispatch(setIsFetching(false));},600)
        })
        .catch((err) => {
            console.log("render error",err);
        });
        }
        if(type !== "" && dateRange[1] !== null)
            fetchData();
      }, [targetSymbol, type, dateRange,accountIds]);
    return (
        <div className='bg-white w-full flex flex-col space-y-4 '>
            <div
                className='flex space-x-2 rounded-md p-2'
            >
                {/*     <Autocomplete
                        key={clientName}
                        //whenever this changes the component will reset.
                        value={targetSymbol}
                        // disablePortal={false}
                        options={stocks}
                        clearOnEscape
                        sx={{ width: 250 }}
                        renderInput={(params) => {
                            return <TextField {...params} label="Trading Symbol" />
                        }} 
                        onChange={(event,value)=>{
                            dispatch(setTargetSymbol(value))
                        }}
                    />
                    {
                        !stocks?.length?
                        <span className={`inline-flex items-center cursor-pointer gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium
                        bg-red-200 text-red-800 dark:bg-blue-800/30 dark:text-blue-500`}
                        >
                          No stock holdings in your account
                        </span>
                        :null
                    }*/}         
                <div className='form-control' style={{ height: '43px', width : '250px', cursor:"pointer", marginLeft: '230px' }}>
              <DatePicker 
                  selectsRange={true}     
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  onChange={(update) => {
                      dispatch(setDateRange([update[0],update[1]]));
                      if (update[1]!==null) dispatch(setDateRange([update[0],update[1]]));
                    } 
                  }
                  isClearable={false}
                  className="nomarg w-64"
                  style={
                    {zIndex:100}
                  }
                  placeholderText='Choose Date Range'
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  portalId={"date-picker"}
                  showIcon
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 48 48"
                    >
                      <mask id="ipSApplication0">
                        <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                          <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                          <path
                            fill="#fff"
                            d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                          ></path>
                        </g>
                      </mask>
                      <path
                        fill="currentColor"
                        d="M0 0h48v48H0z"
                        mask="url(#ipSApplication0)"
                      ></path>
                    </svg>
                  }
                  //for external date z index
                >
                </DatePicker>

                </div>
            </div>
                    {
                        isFetching?
                        <ShimmerThumbnail width={500} />
                        :
                        <PnlLineChartComponent/>
                    }
        </div>
    )
}
export default PnlLineChartContainer;
