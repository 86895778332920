/*eslint-disable*/
import React,{useState, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { useGlobalState,setGlobalState} from "components/globalVariable";
import Cookies from "universal-cookie";
import MarginTemplate from "components/Margin/container/MarginTemplate.js";

import routes from "../../routes.js";
import style from './DemoNavbar2.css';
import { CiUser } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { setUserList } from "reducers/FilterSlice.js";
import { useEffect } from "react";
import config from 'config.js'
import BetaContainer from "components/Beta/BetaContainer.jsx";
import fetchBetaValues from "components/Beta/getPortfoliobeta.js";
import axios from "axios";
import { saveBrokerInfo,saveBroker,saveStrategyList,saveStrategy } from "reducers/FilterSlice.js";
import { setBrokers, setAccounts, setStrategies, selectBroker, selectAccount, selectStrategy, setSupportedAccounts } from 'reducers/BrokerSlice.js';
import { ChevronDownIcon } from "lucide-react";
export const DemoNavbar3 = (props) => {
  const [selectedClients, setSelectedClients ] = useState(null)
  const cookies = new Cookies();
  const oneYearFromNow = new Date();
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [userId,setUserId]=useState('')
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const [clientId] = useGlobalState("clientId")
  
  const [clientName] = useGlobalState("clientName");
  
  const [accountIds] = useGlobalState("accountIds");
  
  const [brokerName] = useGlobalState("brokerName");
  const availableMargin = useSelector(state => state.marginTemplate.availableMargin)
  const elementBtn = useRef(null);
  const userList = useSelector(state => state.filter.userList)
  const broker_info = useSelector(state => state.filter.broker_infos)
  const brokers = useSelector(state => state.broker.brokers);
  const accounts = useSelector(state => state.broker.accounts);
  const supportedAccounts = useSelector(state => state.broker.supportedAccounts);
  const strategies = useSelector(state => state.broker.strategies);
  const selectedBroker = useSelector(state => state.broker.selectedBroker);
  const selectedAccount = useSelector(state => state.broker.selectedAccount);

  const brokerFilter = useSelector(state => state.filter.broker);
  const stratergyList = useSelector(state => state.filter.strategyList);
  const stratergyFilter = useSelector(state => state.filter.strategy)
  
  const dispatch = useDispatch()
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const getBrand = () => {
    let brandName = "Orders";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    //sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  //with useEffect

  const handleBrokerChange = (brokerId) => {
    if(brokerId=="all"){
      const accountIds = broker_info? broker_info.flatMap(broker => broker.account_infos).map(account => account.account_id)  : [];
      setGlobalState("accountIds",accountIds)
      dispatch(selectBroker(""));
    }
    else{
      dispatch(selectBroker(brokerId)); // Set selected broker
    }
    

  };

  useEffect(()=>{
   
    let supported_accounts = broker_info ?broker_info.filter(broker => broker.broker_id==selectedBroker)[0]?.account_infos: []

    dispatch(setSupportedAccounts(supported_accounts?.map(acc => acc.account_id)))
    
  },[selectedBroker])

  useEffect(()=>{
      if(supportedAccounts){
        setGlobalState("accountIds",supportedAccounts)
      }
      
     if(supportedAccounts){
      cookies.set('accountIds', supportedAccounts);
     }
  },[supportedAccounts])


  //from cookies
  useEffect(() => {
    // Function to fetch single client info
    const fetchClientInfo = async (id) => {
      try {
          // Make the API call only if clientId is available
          
          //setGlobalState('clientId',id);
          const response = await axios.get(config.REACT_APP_DEV_URL+`getClientInfo?clientId=${id}`);
          const brokerInfos = response.data.broker_infos;
          dispatch(saveBrokerInfo(response.data.broker_infos));
          // Dispatch action to store brokers (id and name)

          const accountIds = response.data.broker_infos? response.data.broker_infos.flatMap(broker => broker.account_infos).map(account => account.account_id)  : [];

          

          setGlobalState('accountIds', accountIds);

          if(brokerInfos){
            const brokers = brokerInfos?.map(broker => ({
              broker_id: broker.broker_id,
              broker_name: broker.broker_name
            }));
            dispatch(setBrokers(brokers));
            // Store accounts and strategies for each broker
            brokerInfos.forEach(broker => {
              dispatch(setAccounts({
                brokerId: broker.broker_id,
                accounts: broker.account_infos
              }));
            broker.account_infos?.forEach(account => {
                dispatch(setStrategies({
                  accountId: account.account_id,
                  strategies: account.strategies
                }));
              });
            });
  
          }else{
            dispatch(setBrokers(""));
            dispatch(setAccounts(""));
            dispatch(setStrategies(""));
            dispatch(saveStrategy(("")));
            setGlobalState('accountIds',[]);
          }

          dispatch(selectBroker(""));
          dispatch(selectAccount(""));
          dispatch(selectStrategy(""));
          dispatch(saveBroker("ALL"))
          dispatch(saveStrategy(("")));
      } catch (error) {
        console.error('Error fetching client info:', error);
      }
    };
    if (clientId) {
      fetchClientInfo(clientId);
    }
  }, []);

  useEffect(() => {
    // Function to fetch single client info
    //console.log("RUN",selectedClients)
    const fetchClientInfo = async (id) => {
      try {
          const response = await axios.get(config.REACT_APP_DEV_URL+`getClientInfo?clientId=${id}`);
          const brokerInfos = response.data.broker_infos;
          dispatch(saveBrokerInfo(response.data.broker_infos));
          // Dispatch action to store brokers (id and name)
          const accountIds = response.data.broker_infos? response.data.broker_infos.flatMap(broker => broker.account_infos).map(account => account.account_id)  : [];


          setGlobalState('accountIds', accountIds);

          if(brokerInfos){
            const brokers = brokerInfos?.map(broker => ({
              broker_id: broker.broker_id,
              broker_name: broker.broker_name
            }));
            dispatch(setBrokers(brokers));
            // Store accounts and strategies for each broker
            brokerInfos.forEach(broker => {
              dispatch(setAccounts({
                brokerId: broker.broker_id,
                accounts: broker.account_infos
              }));
            broker.account_infos?.forEach(account => {
                dispatch(setStrategies({
                  accountId: account.account_id,
                  strategies: account.strategies
                }));
              });
            });

  
          }else{
            dispatch(setBrokers(null));
            dispatch(setAccounts(null));
            dispatch(setStrategies(null));
            dispatch(saveStrategy(("")));
            setGlobalState('accountIds',[]);
          }

          
      } catch (error) {
        console.error('Error fetching client info:', error);
      }
    };
    // Trigger the fetch when the component loads
    if(selectedClients){
        fetchClientInfo(selectedClients?.client_id)
        setGlobalState('clientName',selectedClients.client_name);
        setGlobalState('clientId',selectedClients.client_id);

          
    }
    dispatch(selectBroker(""));
    dispatch(selectAccount(""));
    dispatch(selectStrategy(""));
    dispatch(saveBroker("ALL"));
    dispatch(saveStrategy(("")));
  }, [selectedClients]);


  React.useEffect(()=>{
    if(clientId){
      cookies.set('clientId', clientId);
      cookies.set('clientName', clientName);
      cookies.set('accountIds', accountIds);
    }
    
    fetchBetaValues(clientId,accountIds,dispatch)
  },[clientId,accountIds,clientName])


  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      //sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);
  const handleMenuClick = () => {
    elementBtn.current?.classList.toggle('open');
    setIsOpen(prev => !prev);
  };
  const getUserlist = () => {
    fetch(config.REACT_APP_DEV_URL+`getClients`, {
        method : 'GET'
      })
      .then(response => {
        if (!response.ok) {
            throw new Error("It is error");
          }
          return response.json();
      })
      .then(data => {
        dispatch(setUserList( data.map((item, index) => ({
        ...item,
    }))));})
      .catch(error => console.error("Error",error))
}
  React.useEffect(()=>{getUserlist()} , [])

  useEffect(() => {
    if (broker_info) {
      const strategies = broker_info
        .filter(broker => brokerFilter === "ALL" || broker?.broker_name === brokerFilter) // Include all brokers if brokerFilter is "ALL"
        .flatMap(broker => broker?.account_infos || [])   // Ensure account_infos is not null
        .flatMap(account => account?.strategies || [])    // Ensure strategies is not null
        .map(strategy => ({
          strategy_id: strategy?.strategy_id,
          strategy_name: strategy?.strategy_name
        }));
      // Remove duplicates based on strategy_id
      const uniqueStrategies = Array.from(
        new Map(strategies.map(item => [item.strategy_id, item])).values()
      );
  
      dispatch(saveStrategyList(uniqueStrategies));
  
    } else {
      dispatch(saveStrategyList([])); // If broker_info is null, save an empty list
    }
  }, [clientId, broker_info, brokerFilter]);

return (
   <>
<nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <p className="text-xl font-bold text-gray-800">{getBrand()}</p>
          </div>
          <div className="hidden lg:flex items-center space-x-4">
            {clientId !== "undefined" && clientId !== undefined && (
              <>
                <div className="flex items-center space-x-4">
                  {/* Placeholder for BetaContainer */}
                  <div className="bg-gray-200 px-3 py-1 rounded-md">
                    <span className="text-sm font-medium text-gray-700">Beta: <BetaContainer/></span>
                  </div>
                  
                  {/* Placeholder for MarginTemplate */}
                  <div className="bg-gray-200 px-3 py-1 rounded-md ">
                    <span className="text-sm font-medium text-gray-700 flex space-x-4">Margin: <MarginTemplate/></span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="hidden lg:flex items-center space-x-4">
            <div className="relative group">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex items-center space-x-2 text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >
                <CiUser className="h-5 w-5" />
                <span className="font-medium">{clientName === 'undefined' || !clientName ? 'Select Profile' : clientName.toUpperCase()}</span>
                <ChevronDownIcon className="h-5 w-5" />
              </button>
              <ul className={`${dropdownOpen ? 'block' : 'hidden'} absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}>
                {userList.length ? (
                  userList.map((item, index) => (
                    <li key={index}>
                      <button
                        onClick={() => {setSelectedClients(item);setDropdownOpen(false)}}
                        className={`${
                          item.client_name === clientName ? 'bg-gray-300 text-gray-900' : 'text-gray-700'
                        } group flex w-full items-center px-4 py-2 text-sm hover:bg-gray-200`}
                      >
                        {item.client_name}
                      </button>
                    </li>
                  ))
                ) : (
                  <li className="px-4 py-2 text-sm text-gray-700">Loading...</li>
                )}
              </ul>
            </div>
            {clientId !== "undefined" && clientId !== undefined && (
              <div className="relative inline-block text-left">
                <select
                  value={brokerFilter}
                  onChange={(e) => dispatch(saveBroker(e.target.value))}
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="" disabled>Select a broker</option>
                  <option value="ALL">ALL</option>
                  {brokers ? (
                    brokers.map(broker => (
                      <option key={broker.broker_id} value={broker.broker_name.toUpperCase()}>
                        {broker.broker_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No brokers allocated</option>
                  )}
                </select>
              </div>
            )}
            {stratergyList?.length > 0 && (
              <div className="relative inline-block text-left">
                <select
                  value={stratergyFilter}
                  onChange={(e) => dispatch(saveStrategy(e.target.value))}
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="" disabled>Select Strategy</option>
                  <option value="ALL">ALL</option>
                  {stratergyList.map((strategy) => (
                    <option key={strategy.strategy_id} value={strategy.strategy_id}>{strategy.strategy_name}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
</>
  );
}



