import React from 'react'
import fetchBetaValues from './getPortfoliobeta'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGlobalState } from 'components/globalVariable'
import { useDispatch } from 'react-redux'

export default function BetaContainer() {

    const betaValue = useSelector(state =>{
        return state.portfolioBeta.portfolioBeta
    })
    const [clientId] = useGlobalState("clientId")
    const [accountIds] = useGlobalState('accountIds')
    const dispatch = useDispatch();

    useEffect(()=>{
        fetchBetaValues(clientId,accountIds,dispatch)
    },[clientId,accountIds,dispatch])

    return (
<span className='text-sm text-black font-semibold'>{betaValue?betaValue:"NA"}</span>
    )
}
