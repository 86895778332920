/*eslint-disable*/
import { useState, useEffect } from "react";
import OptionChain from "./OptionChain";
import { useRef } from "react";
import "./OptionChainTemplate.css";
import OrderForm from "components/OrderForm/Field/OrderForm";
import { useDispatch, useSelector } from "react-redux";
import { setDateList, setMonth, setMonthList, setShowOrderForm, setDate,setStrike, setSymbol,setSymbolName, setBankNiftyDateOptions, setNiftyDateOptions, setFilterModel, setIsFetchingFirst, setIsFetching, setSortOptions, setStrikeList } from "./OptionChainSlice";
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import { event } from "jquery";
import config from '../../config';
import { getBankNiftyEodPrice, getNiftyEodPrice, getValidBankNiftyZenSecurities, getValidNiftyZenSecurities } from "./fetchData";

const OptionChainTemplate = () => 
{

    const [rowData, setRowData] = useState([]);
    const showForm = useSelector(state => state.optionChainTemplate.showOrderForm);
    const tradingSymbol = useSelector(state => state.optionChainTemplate.tradingSymbol);
    const callType = useSelector(state => state.optionChainTemplate.callType);
    const gridApiRef = useRef();
    const date = useSelector(state => state.optionChainTemplate.date);
    const month = useSelector(state => state.optionChainTemplate.month);
    const dateList = useSelector(state => state.optionChainTemplate.dateList);
    const monthList = useSelector(state => state.optionChainTemplate.monthList);
    const dateOptions = useSelector(state => state.optionChainTemplate.dateOptions);
    const symbol = useSelector(state => state.optionChainTemplate.symbol);
    const dispatch = useDispatch();
    const niftyDateOptions = useSelector(state => state.optionChainTemplate.niftyDateOptions);
    const bankNiftyDateOptions = useSelector(state => state.optionChainTemplate.bankNiftyDateOptions);
    const symbolName = useSelector(state => state.optionChainTemplate.symbolName);
    const filterModel = useSelector(state => state.optionChainTemplate.filterModel);
    const isFetchingFirst = useSelector(state => state.optionChainTemplate.isFetchingFirst);
    const isFetching = useSelector(state => state.optionChainTemplate.isFetching);
    const sortOptions = useSelector(state => state.optionChainTemplate.sortOptions);
    const strike = useSelector(state => state.optionChainTemplate.strike);
    const niftyStrikeOptions = useSelector(state => state.optionChainTemplate.niftyStrikeOptions);
    const bankNiftyStrikeOptions = useSelector(state => state.optionChainTemplate.bankNiftyStrikeOptions);
    const strikeList = useSelector(state => state.optionChainTemplate.strikeList);
    const niftyEodPrice = useSelector(state => state.optionChainTemplate.niftyEodPrice);
    const bankNiftyEodPrice = useSelector(state => state.optionChainTemplate.bankNiftyEodPrice);

    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    
    const hideForm = () => 
    {
        dispatch(setShowOrderForm(false))
    }

    const clearFilterForColumn = (colId, gridApi) => 
    {
        const filterModel = gridApi?.getFilterModel();
        delete filterModel[colId];
        gridApi?.setFilterModel(filterModel);
    };
    

    const onSelectMonth = (month, gridApi) => {
        const todayDate = new Date()
        let startDate, endDate
        const existingFilterModel = gridApi?.getFilterModel()
        if(todayDate?.getMonth() === month)
        {
            startDate = new Date(todayDate)?.toISOString()?.split('T')[0]
            endDate = new Date(todayDate?.getFullYear(),todayDate?.getMonth() +1 , 1)?.toISOString()?.split('T')[0]
        }
        else
        {
            startDate = new Date(new Date(todayDate)?.getFullYear(),new Date(todayDate)?.getMonth()+1 , 2)?.toISOString()?.split('T')[0]
            endDate = new Date(new Date(todayDate)?.getFullYear(),new Date(todayDate)?.getMonth() +2 , 1)?.toISOString()?.split('T')[0]
        }
        setTimeout(() => 
        {
            gridApi?.setFilterModel
            (
                {
                    ...gridApi?.getFilterModel(),
                    expiry_date : 
                    {
                        filterType : 'date',
                        type : 'inRange',
                        dateFrom : startDate, 
                        dateTo : endDate,  
                        filterParams : 
                        {
                            inRangeInclusive: true,
                        }    
                    },
                }
            );
            gridApi?.onFilterChanged();
        },0)
        
    }

    const onSelectDate = (date, gridApi) => {
        if (gridApi && date) {
            setTimeout(() => {
                gridApi?.setFilterModel({
                    ...gridApi?.getFilterModel(),
                    expiry_date: {
                        filterType: 'date',
                        type: 'equals',
                        dateFrom: date,   
                        filterParams: {
                            inRangeInclusive: true,
                        }                 
                        
                    },
                });
                gridApi?.onFilterChanged();
            },0)
    }}
    const onSelectSymbol = (symbol, gridApi) => {
        if (gridApi) {
                gridApi?.setFilterModel({
                    ...gridApi?.getFilterModel(),
                    underlying_zen_id: {
                        filterType: 'number',
                        type: 'equals',
                        filter: symbol,   
                        
                    },
                });
                gridApi?.onFilterChanged();

                      
        }
    }
    const onSelectStrike = (strike, gridApi) => {
        if (gridApi) {
            
            gridApi?.setFilterModel({
                ...gridApi?.getFilterModel(),
                strike: 
                {
                    filterType: 'number',
                    type: 'equals',
                    filter: Number(strike),   
                    
                }
            });
            gridApi?.onFilterChanged();
                       
        }
    }
   
    useEffect(() => {
        const fetchData = async () => 
        {
            await Promise.all([
                // getValidNiftyOptionsZenSecurities(dispatch, setNiftyDateOptions),
                // getValidBankNiftyZenSecurities(dispatch, setBankNiftyDateOptions)
                getNiftyEodPrice(dispatch),
                getBankNiftyEodPrice(dispatch)
            ]);
                    
        };
    
        fetchData();
        return () => 
        {
            dispatch(setIsFetching(true));
        }
    }, []);
    
    useEffect(() => {
        if(bankNiftyEodPrice!== "" && niftyEodPrice !== "")
        {
            getValidNiftyZenSecurities(niftyEodPrice, dispatch);
            getValidBankNiftyZenSecurities(bankNiftyEodPrice, dispatch);
        }
    },[bankNiftyEodPrice,niftyEodPrice]);
    useEffect(() => {
        let monthSet;
        if(symbolName === 'NIFTY')
            monthSet = new Set(niftyDateOptions?.map(data => new Date(data)?.getMonth()));
        else
            monthSet = new Set(bankNiftyDateOptions?.map(data => new Date(data)?.getMonth()));
        const sortedMonthSet = Array?.from(monthSet)?.sort()?.map(month => monthNames[month])
        dispatch(setMonthList([...sortedMonthSet]));
        if(isFetchingFirst && niftyDateOptions?.length !=0 && niftyStrikeOptions?.length !=0 && bankNiftyStrikeOptions?.length !=0 && bankNiftyDateOptions?.length !=0)
        {
            dispatch(setMonth(sortedMonthSet[0]));
            if(symbolName ===  'NIFTY')
            {
                dispatch(setDate(niftyDateOptions[0]));
                onSelectDate(niftyDateOptions[0] , gridApiRef.current);
                dispatch(setStrikeList(niftyStrikeOptions));
                dispatch(setDateList(niftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNames?.indexOf(sortedMonthSet[0]))));
            }
            else
            {
                dispatch(setDate(bankNiftyDateOptions[0]));
                onSelectDate(bankNiftyDateOptions[0] , gridApiRef?.current);
                dispatch(setStrikeList(bankNiftyStrikeOptions));
                dispatch(setDateList(bankNiftyDateOptions.filter(date => new Date(date)?.getMonth() === monthNames?.indexOf(sortedMonthSet[0]))));
            }
            dispatch(setIsFetchingFirst(false));
        }    
        if(niftyDateOptions?.length != 0 && bankNiftyDateOptions?.length !=0 && niftyStrikeOptions?.length !=0 && bankNiftyStrikeOptions?.length !=0)
            dispatch(setIsFetching(false));
    }, [niftyDateOptions,bankNiftyDateOptions, niftyStrikeOptions, bankNiftyStrikeOptions]);

    useEffect(()=> {
        if(niftyDateOptions?.length != 0 && bankNiftyDateOptions?.length !=0)
        {
            const eventSource = new EventSource(`${config.DB_CONNECT}getZenOptionGreeks`);
            
            eventSource.onopen = () => {
                console.log("Connection to server opened.");
            };
            
            eventSource.onstatus = (event) => {
                console.log(event);
            };
            
            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const tradingSymbol = data?.key;
                const api = gridApiRef?.current;
                const rowNode = api?.getRowNode(tradingSymbol);
                
                if(rowNode) 
                {                    
                    api?.applyTransaction({update: [data]})
                } 
                else 
                {
                     api?.applyTransaction({add: [data]})
                }
            };
            
            eventSource.onerror = (err) => {
                console.error('EventSource failed:', err);
                eventSource.close();
            };
            
            return () => {
                eventSource.close();
            };
        }
        
    },[isFetching]);

    return(
        <div style={{width: '100%', paddingLeft:'15px'}}>
            <form style={{zIndex: 1}}>
                <div className = 'form-row' style={{zIndex: 1}}>
                <div className="col-md-3 form-group" >
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        defaultValue={symbolName}
                        options={["NIFTY", "BANK-NIFTY"]}
                        disableClearable={true}
                        onChange={(event, value) => 
                        {
                            let date;
                            // clearFilterForColumn('underlying_zen_id', gridApiRef?.current);
                            if(value === "NIFTY")
                            {
                                dispatch(setSymbol(37761));
                                dispatch(setSymbolName("NIFTY"));
                                dispatch(setStrikeList(niftyStrikeOptions));

                                if(strike !== "" && strike !== null)
                                {
                                    // clearFilterForColumn('strike', gridApiRef.current);
                                    dispatch(setStrike(niftyStrikeOptions[0]));
                                    onSelectStrike(niftyStrikeOptions[0], gridApiRef?.current);
                                }
                                else
                                {
                                    date = niftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNames?.indexOf(month) )[0];
                                    dispatch(setDateList(niftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNames?.indexOf(month))));
                                    dispatch(setDate(date));
                                }
                                
                                onSelectSymbol(37761, gridApiRef.current)
                            }
                            else
                            {
                                // clearFilterForColumn('underlying_zen_id', gridApiRef.current)
                                dispatch(setSymbol(37766))
                                dispatch(setSymbolName("BANK-NIFTY"))
                                dispatch(setStrikeList(bankNiftyStrikeOptions));
                                if(strike !== "" && strike !== null)
                                {
                                    // clearFilterForColumn('strike', gridApiRef.current);
                                    dispatch(setStrike(bankNiftyStrikeOptions[0]));
                                    onSelectStrike(bankNiftyStrikeOptions[0], gridApiRef.current);
                                }
                                else
                                {
                                    date = bankNiftyDateOptions.filter(date => new Date(date).getMonth() === monthNames.indexOf(month) )[0];
                                    dispatch(setDateList(bankNiftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNames?.indexOf(month) )));
                                    dispatch(setDate(date));
                                }
                                
                                onSelectSymbol(37766, gridApiRef.current);
                            }                          
                           
                            onSelectDate(date, gridApiRef.current);
                        }}
                        renderInput={(params) => <TextField {...params} label="Select the symbol"/>}
                    />
                    </div>  
                    <div className = 'col-md-3 form-group'>
                        <Autocomplete
                        disablePortal
                        id="combo-box-month"
                        options={monthList}
                        value={month}
                        disableClearable={true}
                        onChange=
                        {
                            (event, value) => 
                            {
                                let date;
                                dispatch(setMonth(value))
                                const monthNumber = monthNames?.indexOf(value) 
                                dispatch(setStrike(""));
                                clearFilterForColumn('strike', gridApiRef.current); 
                                if(symbol === 37761)
                                {
                                    date = niftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNumber )[0];
                                    dispatch(setDateList(niftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNumber )));
                                    dispatch(setDate(date));
                                }
                                else
                                {
                                    date = bankNiftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNumber )[0];
                                    dispatch(setDateList(bankNiftyDateOptions?.filter(date => new Date(date)?.getMonth() === monthNumber )));
                                    dispatch(setDate(date));
                                }
                                onSelectDate(date, gridApiRef.current)                                    
                                
                            }
                        }
                        renderInput={(params) => <TextField {...params} label="Select an expiry month" />}
                        />
                    </div>
                    <div className="col-md-3 form-group" >
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dateList}
                            value={date}
                            disableClearable={true}
                            onChange={(event, value) => {
                                dispatch(setDate(value))
                                onSelectDate(value, gridApiRef.current)                               
                            }}
                            renderInput={(params) => <TextField {...params} label="Select the expiry date"/>}                                       
                        />
                    </div>  
                    <div className="col-md-3 form-group" >
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={strikeList}
                            getOptionLabel={(option) => option.toString()}
                            value={strike}
                            disableClearable={true}
                            onChange={(event, value) => {
                                if(month !== "" && month !== null)
                                {
                                    dispatch(setMonth(""));
                                    dispatch(setDate(""));
                                    dispatch(setDateList([]));
                                    clearFilterForColumn('expiry_date', gridApiRef.current);
                                }                                
                                dispatch(setStrike(value)); 
                                onSelectStrike(value, gridApiRef.current)
                            }}
                            renderInput={(params) => <TextField {...params} label="Select the strike"/>}                                       
                        />
                    </div>                              
                </div>
            </form> 
            <div className="option-container">
                <div className="option-table"> 
                    <OptionChain 
                        date={date}
                        month={month}
                        symbol={symbol}
                        onSelectDate = {onSelectDate}
                        onSelectMonth = {onSelectMonth}
                        monthNames = {monthNames}
                        filterModel = {filterModel}
                        setFilterModel = {setFilterModel}
                        sortOptions = {sortOptions}
                        setSortOptions = {setSortOptions}
                        dispatch = {dispatch}
                        gridApiRef={gridApiRef}
                        setGridApi={(api) => {
                            gridApiRef.current = api;
                        }}
                    />
                </div>
                <div className="order-form">
                    {showForm && <OrderForm
                        targetNode = {tradingSymbol}
                        trade= {callType}
                        setFormOpen={setShowOrderForm}
                        hideForm={hideForm}
                    />}
                </div>
            </div>
        </div>        
         
        )
}

export default OptionChainTemplate;