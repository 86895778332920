/* eslint-disable */
import OrderTable from "../components/OrderTable/OrderTable";
import React from "react";
import OrderFormContainer from "components/OrderForm/Field/OrderFormContainer";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

import { useGlobalState } from "components/globalVariable";


function OrdersGrid() {
  
  const [clientId] = useGlobalState("clientId");
  
  const options = [
    { label: "Buy", value: "BUY", color: "blue" },
    { label: "Sell", value: "SELL", color: "black" },
  ];
  return (
    <>
      <Col xs="auto" width={64}>
        <Card >
          <CardBody>
            <OrderFormContainer options={options}/>
          </CardBody>
        </Card>
      </Col>
          <Col md="auto">
            <Card >
                <OrderTable/>
            </Card>
      </Col>
    </>
  );
}

export default OrdersGrid;
