/*eslint-disable*/
import React, {useMemo,useRef, useCallback} from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import 'ag-grid-community/styles//ag-theme-balham.css';
import 'ag-grid-community/styles//ag-theme-material.css';

import { useState, useEffect } from "react";
import { useGlobalState } from 'components/globalVariable';
import { useSelector,useDispatch } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
import { setTableOptionsData, setFilterText } from '../container/PnlTableOptionSlice';
import TableRefresher from 'components/TableRefreshComponet/TableRefresher';
import config  from "../../../config";
import { Card } from 'reactstrap';
import PnlLineChartContainer from 'components/PnlLineChart/PnlLineChartContainer';

let strategyLists
const PnlGrid = () => {
    const [count, setCount]             = useState(1);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString([], { hour12: true }).replace('pm', 'PM'));
    const { dates,broker, security, exchange, portfolio, entity, strategy } = useSelector(state => state.filter);
    const { columnOptions } = useSelector(state => state.pnlTableOption);
    const dispatch = useDispatch();    
    const [clientId]=useGlobalState("clientId");
    const [accountIds] = useGlobalState("accountIds");
    const [clientName] = useGlobalState('clientName');
    const [rowData, setRowData] = useState();
    const filterText = useSelector(state => state.pnlTableOption.filterText);    
    strategyLists = useSelector(state => state.filter.strategyList);
    const gridRef = useRef(null);

    const onGridReady = (params) => {
        gridRef.current = params.api; // Store the API reference
        calculateSum();
    };

    const getFilteredRows = () => {
        if (gridRef.current) {
            const filteredRows = [];
            gridRef.current.forEachNode((node) => {
                if (node?.rowIndex !== null) { // Only include visible rows
                    filteredRows?.push(node.data);
                }
            });
            return filteredRows;
        }
        return [];
    };
    const dateConversion = (val) => {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); 
        const day = ('0' + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
       
    }

    const  cellClickedListener = ( ) => {
        setCount(count + 1);
    };
    const [topRowData , setTopRowData] = useState({
        realizedLTDPnl: 0,
        unRealizedLTDPnl:   0,
    });

    

    /*
        Total Unrealized PnL = Unrealized PnL from the latest date in the range.  
        Total Realized PnL = Sum of the Realized PnL values across all dates in the range.
    */

    function calculateSum(){
        let latestDate = dateConversion(dates[1]);
        let latestPnl, fullData;
        if(filterText !== "")
        {
            latestPnl = getFilteredRows()?.filter(row=>row?.date===latestDate);
            fullData = getFilteredRows();
        }
        else
        {
            latestPnl = rowData?.filter(row=>row?.date===latestDate);
            fullData = rowData;
        }
        if (rowData) {
            let realizedLTDPnlsum   = 0;
            let unRealizedLTDPnlsum     = 0;
            latestPnl?.forEach(row => {
                unRealizedLTDPnlsum  += Math.round(row?.unRealizedLTDPnl);
            });
            fullData?.forEach(row => {
                realizedLTDPnlsum += Math.round(row?.realizedLTDPnl);
            });
            setTopRowData({
                realizedLTDPnl:  realizedLTDPnlsum,
                unRealizedLTDPnl:  unRealizedLTDPnlsum,
            })
        }
    } 
    useEffect(() => {
        calculateSum();
    }, [rowData, filterText]);
    
    const pinnedTopRowData = useMemo(() => {
        return [
           topRowData
        ];
    }, [topRowData]);
    


    const defaultColDef = {
        sortable: true,
        editable: false,
        filter: true,
       
        headerClass:{textAlign:"right"},
        cellStyle: params => {
            if (params.value < 0) {
                return { color: "red", display: "flex",justifyContent: "flex-end"};
            } else {
                return {display: "flex",justifyContent: "flex-end"};
            }
        }
    }

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { background: "rgb(243, 238, 238)" };
        }
    }
    const [isFetching,setIsFetching] = useState(true);
    useEffect(() => {
        setIsFetching(true)
        const fetchData = () => {
            const body = {
                "startTimestamp": `${dateConversion(dates[0])} 23:15:00`,
                "endTimestamp": `${dateConversion(dates[1])} 23:15:00`,
                positionCompKeyFilter: {
                    clientId: clientId,
                    accountIds: accountIds,
                    tradingSymbols: security === "" ? [] : security,
                    // Conditionally include strategyIds only if strategy is not empty
                    ...((strategy === "ALL" || strategy === "")  ? {  } : { strategyIds : [strategy]}),
                   
                    ...((broker === "ALL" || broker === "") ? {}: { brokers: [broker] })  // Only include brokers if broker is not "ALL"
                }
            };
            fetch(config.REACT_APP_DEV_URL+'pnl',
            {
                method: 'POST',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),         
            })
            .then(result => result.json())
            .then(rowData => {
                setRowData(Array.from(rowData));
                setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }))
                setTimeout(()=>setIsFetching(false),750);
            })
            .catch((err) => {
                console.log("render error");
            });
        }
        fetchData();
        const intervalId = setInterval(fetchData, 120000);
       
        //setIntervel cleaning process
        return(()=>{
            clearInterval(intervalId);
        })
      }, [security, dates, count,clientName, clientId, strategy, broker, accountIds]);

    const containerStyle = useMemo(() => ({  height: 'auto', width : 'auto' , overflow: 'hidden' }), []);
    const gridStyle = useMemo(() => ({ height:"600px",  width : '950px' }), []);
    const filterStyle = useMemo(() => ({ height: '50px', width : '100%' }), []);
    const tableStyle = useMemo(() => ({ height: '100%', width : '100%', "margin" : "15px", position: "relative", "border" : "2px solid black" }), []);
    const tdStyle = useMemo(() => ({ width : '50%' }), []);
    const halfStyle = useMemo(() => ({width : "500px", "padding-left" : "15px"}), []);
    
    
    const [open, setOpen] = React.useState(false);
    const groupIncludeTotalFooter = true;
    const [preVal,setPreVal] = useState('');
    

    //gettinging current table options from redux and updating it
    //then dispatching
    const optionsUpdater = (stateList)=>{
        const newdata = columnOptions.map((option,i)=>{
            return {...option,sort:stateList[i]}
        })
        dispatch(setTableOptionsData(newdata));
    }
    const handleSortingData = (e)=>{
        const state = e.columnApi?.getColumnState();
        const sortState = state?.map(item=>item.sort);
        optionsUpdater(sortState)
    }


    return (
        <>
        
        <Card>
        <TableRefresher cellClickedListener={cellClickedListener} showSpinner={isFetching}/>
            {   
                <div style={gridStyle}  className="ag-theme-alpine">
                    {isFetching?
                    <ShimmerTable row={6} col={6} />
                    :
                    <>
                    <div className="absolute flex items-center space-x-4 my-2 top-[45px] z-10">
                        <div class=" inset-y-0 start-0 flex items-center ps-6 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input
                            type="text"
                            className='bg-slate-50 font-semibold rounded-sm px-4 ps-10 py-2 border text-gray-500'
                            placeholder=" Search by any field"
                            value={filterText}
                            onChange={(e)=>dispatch(setFilterText(e.target.value))}
                        />
                    </div>
                    <AgGridReact
                        columnDefs={[
                            ...columnOptions
                        ]
                        }
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        filterStyle = {filterStyle}
                        ref={gridRef}
                        rowData={rowData}
                        pinnedTopRowData={pinnedTopRowData}
                        getRowStyle={getRowStyle}
                        onSortChanged={handleSortingData}
                        quickFilterText={filterText}  
                    >
                    </AgGridReact>
                    </>
                    }
                </div> 
            }
        </Card>
       
        </>
    )
}

export default PnlGrid;
export {strategyLists}